import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
    <Layout>
        <SEO title="Contacto" />

        <div className="inner-banner">
            <div className="container">
                <div className="inner-title text-center">
                    <h3>Terms & Conditions</h3>
                    <ul>
                        <li>
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <i className="bx bx-chevron-right"></i>
                        </li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
            <div className="inner-banner-shape">
                <div className="shape-one">
                    <StaticImage src="../images/inner-banner/banner-shape1.png" alt="Images" />
                </div>
                <div className="shape-two">
                    <StaticImage src="../images/inner-banner/banner-shape2.png" alt="Images" />
                </div>
                <div className="shape-three">
                    <StaticImage src="../images/inner-banner/banner-shape3.png" alt="Images" />
                </div>
                <div className="inner-banner-dots-2">
                    <StaticImage src="../images/shape/dots-shape.png" alt="Images" />
                </div>
            </div>
        </div>

        <div className="terms-conditions-area pt-50 pb-70">
            <div className="container">
                <div className="single-content">
                    <h3>Welcome to Zinka Terms & Conditions</h3>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam venenatis purus arcu.
                    Sed vitae porttitor lectus. Aenean ac odio quis est suscipit vehicula nec eget arcu.
                    Mauris malesuada libero eu ex pharetra, ac aliquam justo vestibulum. Fusce vulputate
                    est sed orci cursus efficitur. Sed volutpat sit amet mauris eget posuere. Donec a mi
                    eu dolor tincidunt pulvinar quis eleifend diam. Vestibulum diam diam, ultricies id cursus
                    ac, bibendum ac quam.Donec posuere diam eget consequat facilisis. Vestibulum turpis mauris,
                    hendrerit ut risus euismod, maximus iaculis nulla. Quisque tincidunt augue nunc, non pretium
                    nibh consequat vel. Morbi ornare quam neque, nec pharetra magna dapibus quis. Curabitur vehicula
                    ultricies faucibus.
                    </p>
                    <p>
                    Integer mattis magna at nibh semper vulputate. Nunc eu gravida risus, at lobortis ipsum. Suspendisse
                    tempus
                    metus vitae interdum luctus. Pellentesque lectus lorem, laoreet in semper vitae, pellentesque sit
                    amet sapien.
                    Morbi ut nibh a massa suscipit posuere. Nam venenatis eget justo id pulvinar. Quisque dignissim eget
                    turpis nec
                    pulvinar. Vestibulum fermentum lacus sed dignissim mattis. Aenean porttitor vitae lacus nec
                    pellentesque. Nulla
                    sodales vehicula scelerisque. Vestibulum sed auctor arcu, vitae finibus dolor. Nam ultrices
                    consequat odio at gravida.
                    </p>
                </div>
                <div className="single-content">
                    <h3>Welcome to Product Zinka Privacy</h3>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam venenatis purus arcu.
                    Sed vitae porttitor lectus. Aenean ac odio quis est suscipit vehicula nec eget arcu.
                    Mauris malesuada libero eu ex pharetra, ac aliquam justo vestibulum. Fusce vulputate
                    est sed orci cursus efficitur. Sed volutpat sit amet mauris eget posuere. Donec a mi
                    eu dolor tincidunt pulvinar quis eleifend diam. Vestibulum diam diam, ultricies id cursus
                    ac, bibendum ac quam.Donec posuere diam eget consequat facilisis. Vestibulum turpis mauris,
                    hendrerit ut risus euismod, maximus iaculis nulla. Quisque tincidunt augue nunc, non pretium
                    nibh consequat vel. Morbi ornare quam neque, nec pharetra magna dapibus quis. Curabitur vehicula
                    ultricies faucibus.
                    </p>
                    <p>
                    Integer mattis magna at nibh semper vulputate. Nunc eu gravida risus, at lobortis ipsum. Suspendisse
                    tempus
                    metus vitae interdum luctus. Pellentesque lectus lorem, laoreet in semper vitae, pellentesque sit
                    amet sapien.
                    Morbi ut nibh a massa suscipit posuere. Nam venenatis eget justo id pulvinar. Quisque dignissim eget
                    turpis nec
                    pulvinar. Vestibulum fermentum lacus sed dignissim mattis. Aenean porttitor vitae lacus nec
                    pellentesque. Nulla
                    sodales vehicula scelerisque. Vestibulum sed auctor arcu, vitae finibus dolor. Nam ultrices
                    consequat odio at gravida.
                    </p>
                </div>
                <div className="single-content">
                    <h3>Welcome to Services Zinka Conditions</h3>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam venenatis purus arcu.
                    Sed vitae porttitor lectus. Aenean ac odio quis est suscipit vehicula nec eget arcu.
                    Mauris malesuada libero eu ex pharetra, ac aliquam justo vestibulum. Fusce vulputate
                    est sed orci cursus efficitur. Sed volutpat sit amet mauris eget posuere. Donec a mi
                    eu dolor tincidunt pulvinar quis eleifend diam. Vestibulum diam diam, ultricies id cursus
                    ac, bibendum ac quam.Donec posuere diam eget consequat facilisis. Vestibulum turpis mauris,
                    hendrerit ut risus euismod, maximus iaculis nulla. Quisque tincidunt augue nunc, non pretium
                    nibh consequat vel. Morbi ornare quam neque, nec pharetra magna dapibus quis. Curabitur vehicula
                    ultricies faucibus.
                    </p>
                    <p>
                    Integer mattis magna at nibh semper vulputate. Nunc eu gravida risus, at lobortis ipsum. Suspendisse
                    tempus
                    metus vitae interdum luctus. Pellentesque lectus lorem, laoreet in semper vitae, pellentesque sit
                    amet sapien.
                    Morbi ut nibh a massa suscipit posuere. Nam venenatis eget justo id pulvinar. Quisque dignissim eget
                    turpis nec
                    pulvinar. Vestibulum fermentum lacus sed dignissim mattis. Aenean porttitor vitae lacus nec
                    pellentesque. Nulla
                    sodales vehicula scelerisque. Vestibulum sed auctor arcu, vitae finibus dolor. Nam ultrices
                    consequat odio at gravida.
                    </p>
                </div>
            </div>
        </div>

        <div className="brand-logo-area  pt-100">
            <div className="container-fluid">
                <div className="container-max">
                    <div className="brand-logo-slider owl-carousel owl-theme">
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo1.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo2.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo3.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo4.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo5.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo6.png" alt="Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
)

export default TermsPage
